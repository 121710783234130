<template>
  <div class="content_container sourcing request_more">
    <div class="request_title">
      <h1>상품소싱요청</h1>
      <h2>찾으시는 상품이 없으신가요?</h2>
      <p>찾으시는 상품명과 가격대를 작성해주시면 파트너를 매칭해 드립니다.</p>
    </div>

    <div class="fill">
      <!--상품설명 시작-->
      <div class="fill_product_wrap" id="productInfo_box">
        <h1>요청 상품 입력</h1>
        <div class="fill_text">
          <h1>상품명 <span class="star">*</span></h1>
          <input
            type="text"
            placeholder="찾으시는 상품명을 입력해주세요"
            id="title"
            v-model="title"
          />
        </div>
        <div class="fill_select">
          <h1>상품 카테고리 <span class="star">*</span></h1>
          <select
            id="category"
            v-model="category"
            @change="setMinTokenNum($event)"
          >
            <option value="">선택</option>
            <option
              v-for="(item, i) in this.basicData.category.filter(
                (el) => el.level == 1
              )"
              :key="i"
              :data-token="item.token"
              :value="item.category_pk"
            >
              {{ item.content }}
            </option>
          </select>
        </div>

        <div class="fill_explain">
          <h1>상품설명 <span class="star">*</span></h1>
          <textarea
            placeholder="직접 입력해주세요"
            v-model="content"
            id="content"
          ></textarea>
        </div>
        <div class="expose">
          <h1>문의 공개</h1>
          <div class="switch_toggle">
            <input type="checkbox" id="active" v-model="exposure_yn" />
            <label for="active"></label>
          </div>
        </div>
        <p class="fill_box_title">
          상품 이미지<strong>(각 이미지당 최대 2.5MB)</strong>
        </p>
        <div class="fill_image">
          <div class="product_image" v-for="i in 5" :key="i">
            <img
              v-if="!this.image[i - 1]"
              src="@/assets/images/register/icon_image_add.svg"
              class="add_image"
              alt="더하기"
              @click="$refs.imageInput.click()"
            />
            <img v-if="this.image[i - 1]" :src="imageThumb[i - 1]" alt="imageThumb" />
            <span v-if="this.image[i - 1]" @click="deleteProductImage(i - 1)">
              <img
                src="@/assets/images/register/icon_image_remove.svg"
                alt="빼기"
              />
            </span>
          </div>
          <input
            type="file"
            style="display: none"
            multiple
            @change="onImageSelected($event)"
            accept="image/*"
            capture="camera"
            ref="imageInput"
            @click="
              () => {
                this.$refs.imageInput.value = '';
              }
            "
          />
        </div>
        
      </div>
      <!--상품설명 끝-->

      <div class="fill_double_wrap" id="price_box">
        <!--가격대 시작-->
        <div class="fill_number_wrap">
          <h1>
            희망가격대
            <p>
              희망 가격 범위를 입력해주세요. 예산 범위에 맞는 파트너를
              매칭해드립니다.
            </p>
          </h1>
          <div class="fill_number">
            <h1>최소 가격 <span class="star">*</span></h1>
            <input
              type="number"
              placeholder="숫자만 입력해주세요"
              v-model="min_price"
              id="min_price"
            />
          </div>
          <div class="fill_number">
            <h1>최대 가격 <span class="star">*</span></h1>
            <input
              type="number"
              placeholder="숫자만 입력해주세요"
              v-model="max_price"
              id="max_price"
            />
          </div>
          <div class="fill_number">
            <h1>개당 가격 <span class="star">*</span></h1>
            <input
              type="number"
              placeholder="숫자만 입력해주세요"
              v-model="price"
              id="price"
            />
          </div>
        </div>
        <!--가격대 끝-->

        <!--토큰 시작-->
        <div class="token_wrap" id="token_box">
          <h1>거래토큰 사용</h1>
          <p class="min_token">
            최소사용 토큰 : <span>{{ this.minToken }}</span>
          </p>
          <div class="token_select">
            <div class="token_have">
              <p>보유 토큰</p>
              <h2>
                {{ this.numberFormat(this.user.meta_token) }}<span>개</span>
              </h2>
            </div>
            <div class="token_use">
              <button class="minus" @click="changeMetaToken('-', $event)">
                -
              </button>
              <div class="fill_number">
                <h1>사용할 토큰</h1>
                <input
                  type="text"
                  id="token_to_use"
                  :value="this.meta_token"
                  @input="changeMetaToken('=', $event)"
                />
              </div>
              <button class="plus" @click="changeMetaToken('+', $event)">
                +
              </button>
            </div>
          </div>
        </div>
        <!--토큰 끝-->
      </div>
    </div>

    <div class="btn_wrap">
      <button class="common_button" id="btn_submit" @click="submit">
        작성하기
      </button>
      <button class="sub_button" @click="this.$router.go(-1)">이전</button>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/utils/fetchData";
import { postData } from "@/utils/postData";
import { numberFormat } from "@/utils/numberFormat";
import { changeMetaToken } from "@/utils/changeMetaToken";
import { mapState } from "vuex";

export default {
  mounted() {
    this.getBasicData();
  },
  data() {
    return {
      minToken: 0,

      // 보낼 데이터
      title: "",
      category: "",
      content: "",
      image: [],
      imageThumb: [],
      min_price: "",
      max_price: "",
      price: "",
      meta_token: 0,
      exposure_yn: true,

      // 받아올 데이터
      basicData: {
        category: [],
      },
    };
  },
  mixins: [fetchData, postData, numberFormat, changeMetaToken],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("access_token", ["access_token"]),
  },
  watch: {
    meta_token(newVal) {
      if (newVal < this.minToken) {
        document.getElementById("btn_submit").disabled = true;
        document.querySelector(".min_token").classList.add("lack");
      } else {
        document.getElementById("btn_submit").disabled = false;
        document.querySelector(".min_token").classList.remove("lack");
      }
    },
    minToken(newVal) {
      if (newVal > this.meta_token) {
        document.getElementById("btn_submit").disabled = true;
        document.querySelector(".min_token").classList.add("lack");
      } else {
        document.getElementById("btn_submit").disabled = false;
        document.querySelector(".min_token").classList.remove("lack");
      }
    },
  },
  methods: {
    getBasicData() {
      this.fetchData("/getBasic?page=sourcing", (data) => {
        this.basicData.category = data.category;
      });
    },
    // 상품 이미지 추가
    onImageSelected(event) {
      // 파일이 존재하는지
      if (event.target.files && event.target.files[0]) {
        if (event.target.files.length + this.image.length > 5) {
          this.$store.commit("alert", "최대 5개를 선택해주세요");
          return;
        }
        for (let i = 0; i < event.target.files.length; i++) {
          // 2.5MB보다 크면 안받도록
          if (event.target.files[i].size > 1048576 * 2.5) {
            this.$store.commit("alert", "2.5MB 이하 이미지를 선택해 주세요");
          }
          // 이미지일 때만 this.image에 넣음
          else if (
            !event.target.files[i].type.includes("image") ||
            event.target.files[i].type.includes("gif")
          ) {
            this.$store.commit("alert", "이미지만 선택해주세요. (.gif 제외)");
          } else {
            this.image.push(event.target.files[i]);

            const reader = new FileReader();
            reader.onload = (event) => {
              this.imageThumb.push(event.target.result);
            };
            reader.readAsDataURL(event.target.files[i]);
          }
        }
      }
    },
    // 상품 이미지 삭제
    deleteProductImage(i) {
      this.image.splice(i, 1);
      this.imageThumb.splice(i, 1);
    },
    // 최소 사용 토큰 변경
    setMinTokenNum(e) {
      if (e.target.options.selectedIndex > -1) {
        if (
          e.target.options[e.target.options.selectedIndex].dataset.token == null
        ) {
          this.minToken = 0;
        } else {
          this.minToken =
            e.target.options[e.target.options.selectedIndex].dataset.token;
        }
      }
    },
    submit() {
      // 필수 입력 체크
      if (!this.title) {
        this.$store.commit("alert", "상품명을 입력해주세요.");
        this.toggleRequiredBox(document.getElementById("productInfo_box"));
        document.getElementById("title").focus();
      } else if (!this.category) {
        this.$store.commit("alert", "상품 카테고리를 선택해주세요.");
        this.toggleRequiredBox(document.getElementById("productInfo_box"));
        document.getElementById("category").focus();
      } else if (!this.content) {
        this.$store.commit("alert", "상품 설명을 입력해주세요.");
        this.toggleRequiredBox(document.getElementById("productInfo_box"));
        document.getElementById("content").focus();
      } else if (!this.min_price) {
        this.$store.commit("alert", "최소 가격을 입력해주세요.");
        this.toggleRequiredBox(document.getElementById("price_box"));
        document.getElementById("min_price").focus();
      } else if (!this.max_price) {
        this.$store.commit("alert", "최대 가격을 입력해주세요.");
        this.toggleRequiredBox(document.getElementById("price_box"));
        document.getElementById("max_price").focus();
      } else if (!this.price) {
        this.$store.commit("alert", "개당 가격을 입력해주세요.");
        this.toggleRequiredBox(document.getElementById("price_box"));
        document.getElementById("price").focus();
      } else if (this.minToken > this.meta_token) {
        this.$store.commit("alert", "최소 토큰 이상을 사용해주세요");
        this.toggleRequiredBox(document.getElementById("token_box"));
        document.getElementById("token_to_use").focus();
      } else {
        const formData = new FormData();
        formData.append("access_token", this.access_token); // 유저 토큰 (*)
        formData.append("division", "상품소싱"); // 문의 유형 (*)
        formData.append("title", this.title); // 상품명 (*)
        formData.append("category", this.category); // 상품 카테고리 (*)
        formData.append("content", this.content); // 상품설명 (*)
        formData.append("exposure_yn", this.exposure_yn); // 공개여부 (*)
        formData.append("imageCount", this.image.length); // 이미지 개수
        for (let i = 0; i < this.image.length; i++) {
          formData.append(`image${i}`, this.image[i]); // 상품 이미지
        }
        formData.append("min_price", this.min_price); // 최소 가격 (*)
        formData.append("max_price", this.max_price); // 최대 가격 (*)
        formData.append("price", this.price); // 개당 가격(단가) (*)
        formData.append("meta_token", this.meta_token); // 사용할 바이셀토큰
        this.$store.commit("user/metaTokenUse", this.meta_token); // 메타토큰 사용

        this.toggleRequiredBox(null);
        this.postData("/saveInquire", formData, () => {
          this.$store.commit("alert", "요청이 등록되었습니다");
          this.$router.go(-1);
        });
      }
    },
    toggleRequiredBox(element = null) {
      const productInfo_box = document.getElementById("productInfo_box");
      const price_box = document.getElementById("price_box");
      const token_box = document.getElementById("token_box");

      let requiredBoxes = [productInfo_box, price_box, token_box];

      requiredBoxes.forEach((el) => el.classList.remove("required_box"));

      if (element != null) {
        element.classList.add("required_box");
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/layout_request.css">
</style>